<template>
    <Layout>
        <div v-if="loading || loadingHeatMap" class="d-flex justify-content-center align-items-center">
            <b-spinner type="grow" variant="danger" label="Loading..."></b-spinner>
        </div>
        <mapView v-else :markerLocations="heatLocations" :lat="defaultLocation.lat" :customHeight="800" 
            :lng="defaultLocation.lng" />
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { analytics } from "@/config/api/analytics";
import mapView from "@/components/maps/mapPathView";

export default {
    components: {
        Layout,
        mapView,
    },
    mounted() {
        this.loadAnalytics();
        this.loadHeatMapLocations();
    },
    data() {
        return {
            heatLocations: [],
            heatMapPoints: [],
            mapOptions: {
                radius: 75,
            },
            title: "Dashboard",
            data: null,
            defaultLocation: {
                lat: 24.7136,
                lng: 46.6753,
                zoom: 10,
            },
            loading: false,
            loadingHeatMap: true,
            items: [
                {
                    text: "BSEEN",
                },
                {
                    text: "Dashboard",
                    active: true,
                },
            ],
        };
    },
    methods: {
        loadAnalytics() {
            const api = analytics.get;
            this.loading = true;
            this.generateAPI(api)
                .then((res) => {
                    this.data = {
                        vehiclesWithRunningAd: res.data.vehiclesWithRunningAd.length,
                        inspections: res.data.inspections.length,
                        approvedTransactions: res.data.approvedTransactions.length,
                        unAssignedVehicles: res.data.unAssignedVehicles.length,
                    };
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadHeatMapLocations() {
            this.loadingHeatMap = true;
            const api = analytics.admin_heatMapLocations;
            api.params = { campaignId: this.$route.params.campaignId };
            this.generateAPI(api)
                .then((res) => {
                    if (res.data.dashboardHeatLocations) {
                        for (const key in res.data.dashboardHeatLocations) {
                            if (
                                Object.hasOwnProperty.call(res.data.dashboardHeatLocations, key)
                            ) {
                                const element = res.data.dashboardHeatLocations[key];
                                let coordinates = element.location_LngLat.coordinates;
                                let lat = coordinates[1];
                                let lng = coordinates[0];
                                this.heatMapPoints.push({ lat: lat, lng: lng });
                                let user = element.user;
                                this.heatLocations = [...this.heatLocations, { lat, lng, element, user }];
                            }
                        }
                    }
                    // this.defaultLocation.lat = this.heatMapPoints[0].lat;
                    // this.defaultLocation.lng = this.heatMapPoints[0].lng;
                    // this.heatLocations = res.data;
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => this.loadingHeatMap = false);
        },
    },
};
</script>

<style>
</style>
